import { format } from 'date-fns'

/**
 *
 * @param value Date | String value
 * @returns UTC date format--> "2024-01-24"
 */ export const toUTCDate = (fromDate: Date | string): string => {
  {
    var date = new Date(fromDate)
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0]
  }
}

/**
 * Convert date to date string
 * @param value Date | String value
 * @returns UTC date format--> "30 Jan 24"
 */ export const toFriendlyDate = (fromDate: Date | string): string => {
  {
    var date = new Date(fromDate)
    var dateAdjusted = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    return format(dateAdjusted, 'dd MMM yy')
  }
}

/**
 * Convert date to time string
 * @param value Date | String value
 * @returns UTC date format--> "12:10:30"
 */ export const toJustTime = (fromDate: Date | string): string => {
  {
    var date = new Date(fromDate)
    var dateAdjusted = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    return format(dateAdjusted, 'hh:mm:ss')
  }
}

export var convertUTCToDate = (date: string) => {
  {
    const splittedDate = date?.split('-')
    const year = Number(splittedDate?.[0])
    const month = Number(splittedDate?.[1])
    const day = Number(splittedDate?.[2])
    return new Date(year, month - 1, day) // the month is 0-indexed
  }
}
