import { supplierNameMacquarieUpperCase } from '@/constants'
import { mutations as gqMutations, queries } from '@/graphql'
import { apolloClient } from '@/graphql/client'
import {
  ChannelPartnerOrganisation,
  DiscountType,
  Draft,
  FeeSummary,
  Identification,
  IdentificationApprovalStatus,
  IdentificationStep,
  ProductSummary,
  UpdateIdentificationInput
} from '@/graphql/types'
import { RootState } from '@/store'
import { ApolloQueryResult } from 'apollo-client'
import { ToastProgrammatic } from 'buefy'
import get from 'lodash/get'
import { v4 as uuid } from 'uuid'
import { parse } from 'vue-currency-input'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { CURRENCY_FORMAT_OPTIONS_MINZERO, PERCENTAGE_FORMAT_OPTIONS } from '~/constants'

interface IdentificationOverWriterProperties {
  isRegisteredAddressSameAsPrincipalPlaceOfBusiness: boolean
  selectedPostalAddress: null | string
}

interface IdentificationState {
  draftId: string | null
  organisationId: string | null
  editedIdentification: Partial<UpdateIdentificationInput>
  editedDraft: Partial<Draft>
  editedChannelPartners: ChannelPartnerOrganisation[]
  identificationOverWriters: IdentificationOverWriterProperties
  feeSummaries: FeeSummary[]
}
// Getters
export const GET_FEE_SUMMARIES = 'getFeeSummaries'

const initialState: IdentificationState = {
  draftId: null,
  organisationId: null,
  editedIdentification: {},
  editedDraft: {},
  editedChannelPartners: [],
  identificationOverWriters: {
    isRegisteredAddressSameAsPrincipalPlaceOfBusiness: false,
    selectedPostalAddress: null
  },
  feeSummaries: []
}

const state: IdentificationState = { ...initialState }

const getters: GetterTree<IdentificationState, RootState> = {
  getOrganisationId: () => state.organisationId,
  getIdentificationOverWriters: () => state.identificationOverWriters,
  getIdentification: () => state.editedIdentification,
  getDraft: () => state.editedDraft,
  getChannelPartners: () => state.editedChannelPartners,
  [GET_FEE_SUMMARIES]: () => state.feeSummaries
}

const actions: ActionTree<IdentificationState, RootState> = {
  fetchData({ commit }, data) {
    const draftId = data?.draftId || state.draftId
    const organisationId = data?.organisationId || state.organisationId
    if (!draftId || !organisationId) return
    return apolloClient()
      .query({
        query: queries.DRAFT_BG_SUBMISSION_QUERY,
        variables: { draftId, organisationId }
      })
      .then(({ data }) => {
        const { identification, ...draft } = get(data, 'draft', {})
        const organisation = identification.organisation
        delete identification.organisation
        if (!draft.deliveryDetails)
          draft.deliveryDetails = {
            address: '',
            recipientFamilyName: '',
            recipientGivenName: '',
            phoneNumber: '',
            companyName: ''
          }
        if (draft.feeSummary?.supplier?.name?.toUpperCase()?.includes(supplierNameMacquarieUpperCase))
          draft.additionalSupplierDetail = draft.additionalSupplierDetail ?? {
            hasFundingAccountAuthorisation: false,
            fundingAccountBsb: '',
            fundingAccountNumber: ''
          }
        else if (!draft.feeSummary?.supplier?.name?.toUpperCase()?.includes(supplierNameMacquarieUpperCase))
          draft.additionalSupplierDetail = null
        draft.invoiceRecipientEmailAddresses = draft.invoiceRecipientEmailAddresses?.length
          ? draft.invoiceRecipientEmailAddresses
          : ''
        commit('setIdentification', identification)
        commit('setDraft', draft)
        commit('setOrganisationId', organisationId)
        commit('setDraftId', draftId)
        commit('setChannelPartners', organisation?.channelPartners ?? [])
      })
      .catch(error => ToastProgrammatic.open({ message: error.message, type: 'is-danger' }))
  },

  fetchFeeSummaries({ commit }, data) {
    const organisationId = data?.organisationId || state.organisationId
    if (!organisationId) return

    return apolloClient()
      .query({
        query: queries.PRODUCT_SELECTION_SUMMARY,
        variables: { organisationId }
      })
      .then(({ data }: ApolloQueryResult<{ productSelectionSummary: ProductSummary[] }>) => {
        commit('setFeeSummaries', data.productSelectionSummary)
      })
      .catch(error => ToastProgrammatic.open({ message: error.message, type: 'is-danger' }))
  },
  commitIdentification({ commit }, data) {
    const { editedIdentification, identificationOverWriters }: IdentificationState = state
    if (!editedIdentification.id) return

    const input: UpdateIdentificationInput = {
      clientMutationId: uuid(),
      id: editedIdentification.id,
      isCompleted: false
    }

    input.companyDetailsACN = editedIdentification.companyDetailsACN
    input.companyDetailsIsListed = editedIdentification.companyDetailsIsListed
    input.companyDetailsIsRegulated = editedIdentification.companyDetailsIsRegulated
    input.companyDetailsIsSubsidiaryListed = editedIdentification.companyDetailsIsSubsidiaryListed
    input.companyDetailsIsNormalType = editedIdentification.companyDetailsIsNormalType
    input.companyDetailsHasBeneficialOwners = editedIdentification.companyDetailsHasBeneficialOwners
    input.companyDetailsListedExchangeName = editedIdentification.companyDetailsListedExchangeName
    input.companyDetailsName = editedIdentification.companyDetailsName
    input.identificationIndustry = editedIdentification.identificationIndustry
    input.industryAdditionalDetails = editedIdentification.industryAdditionalDetails
    input.entityWealthAccumulation = editedIdentification.entityWealthAccumulation
    input.companyDetailsNatureOfBusiness = editedIdentification.companyDetailsNatureOfBusiness
    input.companyDetailsPrincipalPlaceOfBusiness = editedIdentification.companyDetailsPrincipalPlaceOfBusiness
    input.companyDetailsRegisteredAddress = editedIdentification.companyDetailsRegisteredAddress
    input.companyDetailsRegulatorLicenseNumber = editedIdentification.companyDetailsRegulatorLicenseNumber
    input.companyDetailsRegulatorName = editedIdentification.companyDetailsRegulatorName
    input.companyDetailsSubsidiaryListedCompanyName = editedIdentification.companyDetailsSubsidiaryListedCompanyName
    input.companyDetailsSubsidiaryListedExchangeName = editedIdentification.companyDetailsSubsidiaryListedExchangeName
    input.companyDetailsTaxFatcaStatus = editedIdentification.companyDetailsTaxFatcaStatus
    input.companyDetailsTaxFatcaStatusOther = editedIdentification.companyDetailsTaxFatcaStatusOther
    input.companyDetailsTaxForeignResidentCountry = editedIdentification.companyDetailsTaxForeignResidentCountry
    input.companyDetailsTaxGIIN = editedIdentification.companyDetailsTaxGIIN
    input.companyDetailsTaxHasBeneficialOwnerForeignResident =
      editedIdentification.companyDetailsTaxHasBeneficialOwnerForeignResident
    input.companyDetailsTaxHasGIIN = editedIdentification.companyDetailsTaxHasGIIN
    input.companyDetailsTaxIsForeignResident = editedIdentification.companyDetailsTaxIsForeignResident
    input.companyDetailsTaxType = editedIdentification.companyDetailsTaxType
    input.companyDetailsOnlyOperatesInAustralia = editedIdentification.companyDetailsOnlyOperatesInAustralia
    input.dataType = editedIdentification.dataType
    input.entityType = editedIdentification.entityType
    input.hasMacAccount = editedIdentification.hasMacAccount
    input.hasDividendsIncome = editedIdentification.hasDividendsIncome
    input.macAccountName = editedIdentification.macAccountName
    input.macAccountNumber = editedIdentification.macAccountNumber
    input.postalAddress = editedIdentification.postalAddress
    input.taxExemptionDetails = editedIdentification.taxExemptionDetails
    input.taxExemptionType = editedIdentification.taxExemptionType
    input.taxStatus = editedIdentification.taxStatus
    input.step = editedIdentification.step
    input.isCompleted =
      editedIdentification.isCompleted &&
      editedIdentification.step === IdentificationStep.REVIEW &&
      editedIdentification.clientStatus === IdentificationApprovalStatus.COMPLETED &&
      data?.shouldValidate
        ? true
        : false
    input.clientStatus = input.isCompleted ? editedIdentification.clientStatus : IdentificationApprovalStatus.PENDING

    input.individuals = editedIdentification.individuals as any // issue with BE difference between UpdateIndividuals and Individuals

    // OverWriters
    if (identificationOverWriters.selectedPostalAddress && identificationOverWriters.selectedPostalAddress !== 'OTHER')
      input.postalAddress = identificationOverWriters.selectedPostalAddress
    if (identificationOverWriters.isRegisteredAddressSameAsPrincipalPlaceOfBusiness)
      input.companyDetailsPrincipalPlaceOfBusiness = input.companyDetailsRegisteredAddress
    return apolloClient()
      .mutate({
        mutation: data?.shouldValidate
          ? gqMutations.IDENTIFICATION_UPDATE_MUTATION
          : gqMutations.IDENTIFICATION_SAVE_MUTATION,
        variables: { input }
      })
      .catch(error => ToastProgrammatic.open({ message: error.message, type: 'is-danger' }))
  },
  commitDraft() {
    const { editedDraft, draftId, organisationId }: IdentificationState = state
    if (!draftId && !organisationId) return
    const { status, megDetails, feeSummary, ...draftInput } = editedDraft
    if (!draftInput.invoiceRecipientEmailAddresses?.length) draftInput.invoiceRecipientEmailAddresses = undefined
    delete draftInput?.completedFinanceJourney
    delete draftInput?.attachments
    const input = {
      clientMutationId: uuid(),
      id: draftId!,
      organisationId: organisationId!,
      ...draftInput
    }
    if (input.invoices) delete input.invoices
    if (input.parties) delete input.parties
    return apolloClient()
      .mutate({
        mutation: gqMutations.DRAFT_BG_SUBMISSION_MUTATION,
        variables: { input }
      })
      .catch(error => ToastProgrammatic.open({ message: error.message, type: 'is-danger' }))
  },
  commitFeeWithDiscount() {
    const { editedDraft, draftId, organisationId }: IdentificationState = state
    if (!draftId && !organisationId) return
    const { feeSummaryId, acceptedChannelPartnerId, establishmentFeeDiscountAmount, discountPercentage } = editedDraft
    const input = {
      clientMutationId: uuid(),
      id: draftId!,
      organisationId: organisationId!,
      feeSummaryId,
      acceptedChannelPartnerId: acceptedChannelPartnerId ?? null,
      establishmentFeeDiscountAmount: establishmentFeeDiscountAmount ? establishmentFeeDiscountAmount : 0,
      discountPercentage: discountPercentage ? discountPercentage : 0,
      additionalSupplierDetail: editedDraft.additionalSupplierDetail
    }
    return apolloClient()
      .mutate({
        mutation: gqMutations.DRAFT_BG_SUBMISSION_MUTATION,
        variables: { input }
      })
      .catch(error => ToastProgrammatic.open({ message: error.message, type: 'is-danger' }))
  },
  clearBgSubmission({ commit }) {
    commit('clearBgSubmission')
  }
}

const mutations: MutationTree<IdentificationState> = {
  setDraftId(state, draftId) {
    state.draftId = draftId
  },
  setOrganisationId(state, organisationId) {
    state.organisationId = organisationId
  },
  setChannelPartners(state, channelPartners) {
    state.editedChannelPartners = channelPartners
  },
  setIdentification(state, identification) {
    state.editedIdentification = identification
  },
  updateIdentification(state, changes: [Partial<Identification>]) {
    Object.entries(changes).forEach(([key, value]) => ((state.editedIdentification as any)[key] = value))
  },
  updateIdentificationOverWriters(state, changes: [Partial<IdentificationOverWriterProperties>]) {
    Object.entries(changes).forEach(([key, value]) => ((state.identificationOverWriters as any)[key] = value))
  },
  setDraft(state, draft) {
    state.editedDraft = draft
  },
  setDraftFeeWithProductDiscount(state, { feeSummary, productDiscount }: ProductSummary) {
    state.editedDraft.feeSummaryId = feeSummary.id
    state.editedDraft.acceptedChannelPartnerId = productDiscount?.channelPartnerId

    state.editedDraft.discountPercentage =
      productDiscount?.discountType === DiscountType.ONGOING_PERCENTAGE
        ? parse(productDiscount?.discountLabel, PERCENTAGE_FORMAT_OPTIONS)
        : 0

    state.editedDraft.establishmentFeeDiscountAmount =
      productDiscount?.discountType === DiscountType.ESTABLISHMENT_FEE_AMOUNT
        ? parse(productDiscount?.discountLabel, CURRENCY_FORMAT_OPTIONS_MINZERO)
        : 0
  },
  updateDraft(state, changes: [Partial<Draft>]) {
    Object.entries(changes).forEach(([key, value]: any) => {
      if (typeof value === 'object') {
        Object.entries(value).forEach(([childKey, childValue]) => {
          state.editedDraft[key as keyof Draft][childKey] = childValue
        })
      } else state.editedDraft[key as keyof Draft] = value
    })
  },
  setFeeSummaries(state, getFeeSummaries) {
    state.feeSummaries = getFeeSummaries
  },
  clearBgSubmission(state) {
    state.draftId = initialState.draftId
    state.editedChannelPartners = initialState.editedChannelPartners
    state.editedDraft = initialState.editedDraft
    state.editedIdentification = initialState.editedIdentification
    state.feeSummaries = initialState.feeSummaries
    state.identificationOverWriters = initialState.identificationOverWriters
    state.organisationId = initialState.organisationId
  }
}

const mod: Module<IdentificationState, RootState> = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
}

export default mod
