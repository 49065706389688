import CryptoJS from 'crypto-js'
import { DraftAttachment } from '~/types'

const readChunked = (file: File, chunkCallback: CallableFunction, endCallback: CallableFunction) => {
  var fileSize = file.size
  var chunkSize = 4 * 1024 * 1024 // 4MB
  var offset = 0

  var reader = new FileReader()
  reader.onload = () => {
    if (reader.error) {
      endCallback(reader.error || {})
      return
    }
    offset += (reader.result! as string).length
    // callback for handling read chunk
    chunkCallback(reader.result, offset, fileSize)
    if (offset >= fileSize) {
      endCallback(null)
      return
    }
    readNext()
  }

  reader.onerror = err => {
    endCallback(err || {})
  }

  const readNext = () => {
    var fileSlice = file.slice(offset, offset + chunkSize)
    reader.readAsBinaryString(fileSlice)
  }
  readNext()
}

export const calculateMd5Hash = (blob: File, cbProgress?: CallableFunction) => {
  return new Promise((resolve, reject) => {
    var md5 = CryptoJS.algo.MD5.create()
    readChunked(
      blob,
      (chunk: string, offs: number, total: number) => {
        md5.update(CryptoJS.enc.Latin1.parse(chunk))
        if (cbProgress) {
          cbProgress(offs / total)
        }
      },
      (err: any) => {
        if (err) {
          reject(err)
        } else {
          var hash = md5.finalize()
          var hashHex = hash.toString(CryptoJS.enc.Base64)
          resolve(hashHex)
        }
      }
    )
  })
}

interface Attachment {
  fileName: string
  contentType: string
  received: Boolean
  uploadPresignedUrl: string
}

export const uploadFile = async (file: File | null, attachment: DraftAttachment, checksum: string | null = null) => {
  if (!file) return
  if (!checksum) checksum = (await calculateMd5Hash(file)) as string
  const { uploadPresignedUrl, contentType, fileName } = attachment as Attachment
  return fetch(uploadPresignedUrl, {
    headers: {
      'Content-Disposition': `attachment; filename="${fileName}"`,
      'Content-MD5': checksum,
      'Content-Type': contentType
    },
    method: 'PUT',
    body: file
  })
}
